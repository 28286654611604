import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { PRODUCT_DATA } from "./fake-data-product";
import { COLLECTION_DATA } from './fake-data-collections';
import { TokenUser } from "src/core/models";

const products = PRODUCT_DATA;
const collections = COLLECTION_DATA;
const usersData = {
  admin: {
    user: 'admin',
    password: 'admin@123',
    fullname: 'Admin',
    user_scopes: 'manager,cskh'
  },
  toan: {
    user: 'toan',
    password: 'toan123$',
    fullname: 'Toan Le',
    enName: 'Thomas',
    user_scopes: '_admin_,cskh'
  },
  dev: {
    user: 'dev',
    password: 'dev123$',
    fullname: 'Dev',
    enName: 'Thomas',
    user_scopes: '_admin_,cskh'
  },
  quang: {
    user: 'quang',
    password: 'quang123$',
    fullname: 'Quang',
    enName: 'Lucas',
    user_scopes: '_admin_,cskh'
  },
  ta: {
    user: 'ta',
    password: 'ta123$',
    fullname: 'Tuan Anh',
    enName: 'Terry',
    user_scopes: '_admin_,cskh'
  },
  loan: {
    user: 'loan',
    password: 'loan',
    fullname: 'Bich Loan',
    enName: 'Sara',
    user_scopes: 'manager,cskh'
  },
  le: {
    user: 'le',
    password: 'le',
    fullname: 'Le',
    enName: 'Lisa',
    user_scopes: 'manager'
  },
  an: {
    user: 'an',
    password: 'an',
    fullname: 'An',
    enName: 'Jane',
    user_scopes: 'manager,cskh'
  }
};

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;
    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(handleRoute));

    function handleRoute() {
      switch (true) {
        case url.endsWith("/authenticate") && method === "POST":
          if (usersData[body.username]['password'] == body.password) {
            const user = new TokenUser();
            user.access_token = "fakeaccesstoken";
            user.user_scopes = usersData[body.username]['user_scopes'];
            user.team_scopes = ['manager'];
            user.fullname = usersData[body.username]['fullname'];
            user.enName = usersData[body.username]['enName'];
            user.user = usersData[body.username]['user'];
            return ok({
              status: "SUCCESS",
              data: user
            });
          }else {
            console.log('incorrect password');
          }

        case url.endsWith("/products?limit=10&page=0") && method === "GET":
          return getProducts(10, 0);
        case url.endsWith("/products?limit=10&page=1") && method === "GET":
          return getProducts(10, 1);
        case url.endsWith("/products?limit=50&page=0") && method === "GET":
          return getProducts(50, 0);
        case url.endsWith("/products/delete") && method === "DELETE":
          return deleteProduct();
        // collections
        case url.endsWith('/collections?limit=10&page=0') && method === 'GET': return getCollections(10, 0);
        case url.endsWith('/collections?limit=10&page=1') && method === 'GET': return getCollections(10, 1);
        case url.endsWith('/collections?limit=10&page=2') && method === 'GET': return getCollections(10, 2);

        default:
          // pass through any requests not handled above
          return next.handle(request);
      }
    }

    function roundUp(num, precision) {
      precision = Math.pow(10, precision);
      return Math.ceil(num * precision) / precision;
    }

    function getProducts(pagesize, currentpage) {
      const newArray = products.slice(
        pagesize * currentpage,
        currentpage < roundUp(products.length / pagesize, 0)
          ? pagesize * (currentpage + 1)
          : pagesize * currentpage
      );
      // console.log(newArray);
      return ok({
        status: "SUCCESS",
        data: {
          products: newArray,
          total: products.length,
        },
      });
    }
    function getCollections(pagesize, currentpage) {
      const newArray = collections.slice(
        pagesize * currentpage,
        currentpage < roundUp(collections.length / pagesize, 0)
          ? pagesize * (currentpage + 1)
          : pagesize * currentpage
      );
      // console.log(newArray);
      return ok({
        status: "SUCCESS",
        data: {
          collections: newArray,
          total: collections.length,
        },
      });
    }

    function deleteProduct() {
      return ok({
        status: "SUCCESS",
      });
    }

    // helper functions
    function ok(body?) {
      return of(new HttpResponse({ status: 200, body }));
    }

    function error(message) {
      return throwError({ status: "ERROR ", data: { message } });
    }

    function unauthorized() {
      return throwError({ status: 401, error: { message: "Unauthorised" } });
    }
  }
}

export const FakeBackendProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true,
};
